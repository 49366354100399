<!-- @format -->
<script lang="ts">

  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";

  import Button from "../Button.svelte";
  import OfferCard from "./OfferCard.svelte";
  import Carousel from "../Carousel.svelte";
  import Modal from "./Modal.svelte";
  import OfferCardModal from "./OfferCardModal.svelte";
  import type { ManageAPI } from "~/manage-api/types";
  import { getOneOfferPerStore } from "~/util/offer";
  export let request_store_key: StoresKey;
  export let fragment: Fragment;

  const stores_context = getStoresContext(request_store_key);
  const { locale, offers, T } = stores_context;
  const { getText, getLink } = stores_context.fieldUtils();

  $: title = $locale && getText("title", fragment);
  $: link = $locale && getLink('link-button', fragment);
  
  let oneOfferPerStore: ManageAPI.Offer[];
  function focusOffer(event: CustomEvent) {
    id = event.detail;
  }
  let focusedOffer: ManageAPI.Offer;
  $: if (id != 0) {
    focusedOffer = $offers.find((offer) => offer.id === id)!;
  }
  $: {
    if ($offers) {
      oneOfferPerStore = getOneOfferPerStore([...$offers]);
    }
  }

  let id = 0;
  $:t = $T
</script>

{#if oneOfferPerStore.length}
  <Carousel {title}>
    <svelte:fragment slot="filter">
      <div class="switch-wrapper d-none">
        <span>{t`student offers`}</span>
        <label class="switch mx-2">
          <input type="checkbox" name="category" />
          <span class="slider round" />
        </label>
      </div>
    </svelte:fragment>
    <svelte:fragment slot="swiper">
      {#if oneOfferPerStore.length > 3}
        {#each oneOfferPerStore.slice(0, 15) as offer}
          <div class="swiper-slide">
            <OfferCard {offer} on:focusOffer={focusOffer} {request_store_key} />
          </div>
        {/each}
      {:else}
        {#each $offers.slice(0, 15) as offer}
          <div class="swiper-slide">
            <OfferCard {offer} on:focusOffer={focusOffer} {request_store_key} />
          </div>
        {/each}
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="link-button">
      {#if link}
        <Button href={link.href} type="secondary">{link.text}</Button>
      {/if}
    </svelte:fragment>
  </Carousel>

  <Modal bind:id {request_store_key}>
    {#if id}
      <OfferCardModal {request_store_key} offer={focusedOffer} />
    {/if}
  </Modal>
{/if}

<style lang="scss">
  .switch-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 36px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #000;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 6px;
    bottom: 5px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: #000;
  }

  input:checked + .slider {
    background: #fff;
    border: 1px solid var(--color-dark-green);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
    background: var(--color-dark-green);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
