<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import { Navigation, Pagination, Autoplay } from "swiper/modules";
  import type { SwiperOptions } from "swiper/types";
  import { generateRandomID } from "~/util/common";

  export let title: string;

  const id = generateRandomID();
  
  let swiperEl: HTMLElement;
  let swiperInstance: Swiper;
  let isVisible = false;
  let isBeginning = true;
  let isEnd = false;

  const handlePrevClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (swiperInstance && !isBeginning) {
      swiperInstance.slidePrev();
    }
  };

  const handleNextClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (swiperInstance && !isEnd) {
      swiperInstance.slideNext();
    }
  };

  const params: SwiperOptions = {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 24,
    loop: false,
    pagination: {
      type: "fraction",
      el: `#pagination-${id}`,
    },
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
        slidesPerGroup: 3,
        centeredSlides: false,
        loop: false,
        pagination: {
          type: "bullets",
          el: `#pagination-${id}`,
          clickable: true,
          renderBullet: (index: number, className: string) =>
            `<span class="${className}"></span>`,
        },
        autoplay: {
          delay: 5000
        },
      },
    },
    watchOverflow: true,
    observer: true,
    observeParents: true,
    on: {
      init: () => {
        // Make component visible after initialization
        setTimeout(() => {
          isVisible = true;
        }, 100);
      },
      slideChange: () => {
        if (swiperInstance) {
          isBeginning = swiperInstance.isBeginning;
          isEnd = swiperInstance.isEnd;
        }
      }
    }
  };

  onMount(() => {
    if (!swiperEl) return;
    
    setTimeout(() => {
      swiperInstance = new Swiper(swiperEl, params);
      isBeginning = swiperInstance.isBeginning;
      isEnd = swiperInstance.isEnd;
    }, 100);
  });
</script>

<div class="eften2025-carousel row py-lg-6 py-5">
  <div class="col-12 mb-lg-5 mb-4">
    <div class="header">
      <h2 class="lift-title">{@html title}</h2>
      <slot name="filter" />
    </div>
  </div>
  <div class="col-12">
    <div class="swiper-container" class:is-visible={isVisible}>
      <div class="swiper" bind:this={swiperEl}>
        <div class="swiper-wrapper">
          <slot name="swiper" />
        </div>
      </div>
    </div>
    <div class="control">
      <div class="control-navigation">
        <button 
          class="navigation-button" 
          id="prev-{id}" 
          on:click={handlePrevClick}
          aria-label="Previous slide"
          class:disabled={isBeginning}
          disabled={isBeginning}>
          <i class="fa fa-chevron-left" />
        </button>
        <button 
          class="navigation-button" 
          id="next-{id}" 
          on:click={handleNextClick}
          aria-label="Next slide"
          class:disabled={isEnd}
          disabled={isEnd}>
          <i class="fa fa-chevron-right" />
        </button>
      </div>
      <div class="swiper-pagination ml-md-4" id="pagination-{id}" />
      <div class="d-none d-md-block ml-auto">
        <slot name="link-button" />
      </div>
    </div>
    <div class="d-md-none mt-4">
      <slot name="link-button" class="d-md-none" />
    </div>
  </div>
</div>

<style lang="scss">
  .eften2025-carousel {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .swiper-container {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;
      
      &.is-visible {
        visibility: visible;
        opacity: 1;
      }
    }

    .swiper {
      width: 100%;
      overflow: hidden;
    }

    .control-navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      :global(.swiper-button-disabled) {
        opacity: 0.4;
      }
    }

    .navigation-button {
      width: 32px;
      height: 32px;
      border: 1px solid var(--brand-color-1);
      border-radius: 50%;
      position: unset;
      display: inline-flex;
      margin-right: 16px;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      cursor: pointer;
      background: transparent;
      padding: 0;
      i {
        color: var(--brand-color-1);
      }
      
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .swiper-pagination {
      position: unset;
      display: flex;
      position: absolute;
      left: 50%;
      justify-content: center;
      top: 0;
      height: 100%;
      width: 50% !important;
      align-items: center;
      @media screen and (min-width: 768px) {
        position: unset;
        margin-left: 32px;
        width: 75% !important;
      }

      :global(.swiper-pagination-bullet) {
        border: 1px solid var(--brand-color-1);
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        font-size: 12px;
        color: var(--brand-color-1);
        margin-right: 0.7rem;
        border-radius: 50%;
      }
      :global(.swiper-pagination-bullet-active) {
        color: var(--brand-color-1);
        background: var(--brand-color-1);
      }
    }
    
    .control {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      @media screen and (min-width: 768px) {
        justify-content: space-between;
      }
      @media only screen and (min-width: 992px) {
        justify-content: flex-start;
      }
    }
  }
</style>
